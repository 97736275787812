import { defineStore } from 'pinia'
import type { GlobalState } from './helper'
import { store } from '@/store'


export const useReplicateStore = defineStore('replicate-store', {
  state: (): GlobalState => ({
    ModelsList: {},
    submitData:{}
  }),

  actions: {
    updateModelsList(state: object){
      this.ModelsList = state 
    },
    updateSubmitData(state: object){
      this.submitData = state 
    },
  },
})

export function useReplicateStoreWithOut() {
  return useReplicateStore(store)
}