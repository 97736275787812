<script setup lang='ts'>
import { NButton, NModal, NScrollbar, NSkeleton, useMessage } from 'naive-ui'
import { computed, ref, nextTick } from 'vue'
import { marked } from 'marked'
import { useBasicLayout } from '@/hooks/useBasicLayout'
import { useAppStore, useAuthStore, useGlobalStoreWithOut } from '@/store'
import { fetchGenInviteCodeAPI, fetchGetInviteRecordAPI } from '@/api/user'
import { copyText } from '@/utils/format'
import { fetchGetGlobalNoticeAPI } from '@/api/global'
import type { ResData } from '@/api/types'
import { ss } from '@/utils/storage'
import { SvgIcon } from '@/components/common'
const ms = useMessage()
defineProps<Props>()
const authStore = useAuthStore()
const logoPath = computed(() => authStore.globalConfig.clientLogoPath)
const isLogin = computed(() => authStore.isLogin)
interface Props {
  visible: boolean
}

const { isMobile } = useBasicLayout()
const appStore = useAppStore()
const useGlobalStore = useGlobalStoreWithOut()
const loading = ref(true)
const darkMode = computed(() => appStore.theme === 'dark')
const theme = computed(() => appStore.theme)





async function openDrawerAfter() {
  await queryNotice()
  loading.value = false
}


function handleCloseDialog() {
  loading.value = true
}

function handleClose() {
  useGlobalStore.updateMessageCenterDialog(false)
}



function handleReminder() {
  useGlobalStore.updateNoticeDialog(false)
  ss.set('showNotice', Date.now() + 24 * 60 * 60 * 1000)
}
</script>

<template>
  <NModal :show="visible" class="noticeDialog h-full w-11/12 md:w-3/5" :on-after-enter="openDrawerAfter"
    transform-origin="center"
    :close-on-esc="true"
    :on-after-leave="handleCloseDialog" 
    :style="{
      backgroundColor: appStore.theme === 'dark' ? '#131314' : '#fff',
      
    }">
    <div class="px-[15px] rounded-md relative">
      <div class="h-[100px] flex justify-between items-center" :class="isMobile ? '' : 'px-[30px]'">
        <div class="flex items-center">
          <span>
            <img :src="logoPath" class="w-[40px]">
          </span>
          <span class="text-[24px] text-primary  ml-5 font-medium">{{'消息中心' }}</span>
        </div>
        <span v-if="!isMobile">
        </span>
        <span
          class="absolute right-[12px] top-[12px] rounded-sm hover:bg-[#e8e8e8] transition-all cursor-pointer p-[2px]">
          <SvgIcon icon="ic:twotone-close" class="text-[20px]" @click="handleClose" />
        </span>
      </div>
    </div>
  </NModal>
</template>

<style scoped>
:deep(.n-card__content) {
  padding: 0 !important;
  
}

.my {
  background-color: #dee8ff;
  color: #0057ff;
  --n-border: 1px solid transparent !important;
  font-weight: bold !important;

  &:hover {
    background-color: #dee8ff !important;
    color: #0057ff !important;
    --n-border-hover: 1px solid #bfd2ff !important;
  }
}

.tips {
  background-color: #fff;
  color: #000;
  --n-border: 1px solid transparent !important;
  font-weight: bold !important;

  &:hover {
    background-color: #dee8ff !important;
    color: #0057ff !important;
    --n-border-hover: 1px solid #bfd2ff !important;
  }
}


</style>
