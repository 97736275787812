import CryptoJS from 'crypto-js'

/**
 * 转义 HTML 字符
 * @param source
 */
export function encodeHTML(source: string) {
  return source
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#39;')
}

/**
 * 判断是否为代码块
 * @param text
 */
export function includeCode(text: string | null | undefined) {
  const regexp = /^(?:\s{4}|\t).+/gm
  return !!(text?.includes(' = ') || text?.match(regexp))
}

export function parseCyp(text, se, int) {
  try {
    const key = CryptoJS.enc.Utf8.parse(se)
    const iv = CryptoJS.enc.Utf8.parse(int)
    const decrypted = CryptoJS.AES.decrypt(text, key, { iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 })
    return decrypted.toString(CryptoJS.enc.Utf8)
  }
  catch (error) {
    return null
  }
}

/**
 * 复制文本
 * @param options
 */
export function copyText(options: { text: string; origin?: boolean }) {
  const props = { origin: true, ...options }
  let input: HTMLInputElement | HTMLTextAreaElement
  if (props.origin)
    input = document.createElement('textarea')
  else
    input = document.createElement('input')

    console.log(props.text)
  input.setAttribute('readonly', 'readonly')
  input.value = props.text
  document.body.appendChild(input)
  input.select()
  // if (document.execCommand('copy'))
  //   document.execCommand('copy')
  try {
    const success = document.execCommand('copy')
    console.log('复制是否成功：', success)  // 打印复制结果
  } catch (err) {
    console.log('复制失败:', err)
  }
  document.body.removeChild(input)
}

export async function copyToClipboard(text: string): Promise<void> {
  if (!text) {
    console.warn('复制的文本不能为空');
    return Promise.reject(new Error('文本为空，无法复制'));
  }

  try {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      // 使用现代 Clipboard API
      await navigator.clipboard.writeText(text);
      console.log('复制成功:', text);
    } else {
      // 回退到 document.execCommand 方法
      const input = document.createElement('textarea');
      input.style.position = 'absolute';
      input.style.left = '-9999px';
      input.value = text;
      document.body.appendChild(input);
      input.select();
      document.execCommand('copy');
      document.body.removeChild(input);
      console.log('复制成功:', text);
    }
  } catch (error) {
    console.error('复制失败:', error);
    throw new Error('复制失败');
  }
}


export function copyTextCopy(options: { text: string; origin?: boolean }) {
  const props = { origin: true, ...options }
  let input: HTMLInputElement | HTMLTextAreaElement

  if (navigator.clipboard?.writeText) {
    // 使用现代 Clipboard API
    navigator.clipboard.writeText(props.text)
      .then(() => {
        console.log('复制成功')
      
      })
      .catch((err) => {
        console.log('复制失败:', err)

      })
  } else {
    // 使用 document.execCommand 作为后备方案
    if (props.origin) input = document.createElement('textarea')
    else input = document.createElement('input')

    input.setAttribute('readonly', 'readonly')
    input.value = props.text
    document.body.appendChild(input)
    input.select()

    try {
      const success = document.execCommand('copy')
      if (success) {
        console.log('复制成功')

      } else {
        console.log('复制失败')

      }
    } catch (err) {
      console.log('复制失败:', err)

    }

    document.body.removeChild(input)
  }
}


export function utcToShanghaiTime(utcTime: string, format: string): string {
  const date = new Date(utcTime)
  const shanghaiTime = date.getTime() + 8 * 60 * 60 * 1000
  const shanghaiDate = new Date(shanghaiTime)

  let result = format.replace('YYYY', shanghaiDate.getFullYear().toString())
  result = result.replace('MM', (`0${shanghaiDate.getMonth() + 1}`).slice(-2))
  result = result.replace('DD', (`0${shanghaiDate.getDate()}`).slice(-2))
  result = result.replace('hh', (`0${shanghaiDate.getHours()}`).slice(-2))
  result = result.replace('mm', (`0${shanghaiDate.getMinutes()}`).slice(-2))
  result = result.replace('ss', (`0${shanghaiDate.getSeconds()}`).slice(-2))

  return result
}
