<template>
  <CustomModal v-model:visible="showModal" title="自定义弹窗" :maskClosable="false" okText="确定" cancelText="取消"
    @ok="onConfirm" @cancel="onCancel" @close="onClose" @afterEnter="afterEnter">
    <div class="px-[15px] rounded-md relative">
      <div class="h-[100px] flex justify-between items-center" :class="isMobile ? '' : 'px-[30px]'">
        <div class="flex items-center">
          <span>
            <img :src="logoPath" class="w-[40px]">
          </span>

          <span v-if="notice.noticeTitle && !loading" class="text-[24px] text-primary  font-medium"
            :class="isMobile ? ' ml-2' : ' ml-5'">{{
              notice?.noticeTitle }}</span>
          <NSkeleton v-if="loading && !notice?.noticeTitle" class="ml-5" height="40px" width="150px" />
        </div>
        <span v-if="!isMobile">
        </span>
        <span
          class="absolute right-[12px] top-[12px] rounded-sm hover:bg-[#e8e8e8] transition-all cursor-pointer p-[2px]">
          <SvgIcon icon="ic:twotone-close" class="text-[20px]" @click="handleClose" />
        </span>
      </div>

      <div v-if="loading && !html" class="px-[20px]">
        <NSkeleton text :repeat="15" />
      </div>
      <div
        :class="[isMobile ? 'p-[0px]  max-h-[calc(100vh-200px)]' : 'p-[0px]', darkMode ? 'text-[#9e9d9d]' : 'text-[#000]']">
        <NScrollbar>
          <div
            :class="[isMobile ? 'p-[0px]  max-h-[calc(100vh-200px)]' : 'p-[30px]  max-h-[55vh]', darkMode ? 'text-[#9e9d9d]' : 'text-[#000]']"
            class="markdown-body markdown-body-generate pb-8" @mouseenter="attachButtonListeners" v-html="html" />
        </NScrollbar>
      </div>
      <div class="flex w-full pb-6 pt-5 px-5 items-end  justify-end ">
        <NButton type="primary" ghost size="small" class="!ml-[15px] my" @click="handleClose"
          :style="{ backgroundColor: appStore.theme === 'dark' ? '#19191b' : '#dee8ff', color: appStore.theme === 'dark' ? '#9e9d9d' : '' }">
          我已知晓
        </NButton>
        <NButton type="primary" strong size="small" class="!ml-[15px] tips" @click="handleReminder"
          :style="{ backgroundColor: appStore.theme === 'dark' ? '#19191b' : '', color: appStore.theme === 'dark' ? '#9e9d9d' : '' }">
          今日不再提示
        </NButton>
      </div>
    </div>
    <template #footer>
      <button @click="onCancel" class="custom-cancel-btn">自定义取消</button>
      <button @click="onConfirm" class="custom-ok-btn">自定义确认</button>
    </template>
  </CustomModal>
</template>

<script setup lang="ts">
import CustomModal from '@/components/common/CustomModal/index.vue';
import { ss } from '@/utils/storage'
import { SvgIcon } from '@/components/common'
import { defineProps, defineEmits, watch, ref, computed, nextTick } from 'vue';
import { useAppStore, useAuthStore, useGlobalStoreWithOut } from '@/store'
import { fetchGetGlobalNoticeAPI } from '@/api/global'
import { NButton, NScrollbar, NSkeleton, useMessage } from 'naive-ui'
import { fetchGenInviteCodeAPI, fetchGetInviteRecordAPI } from '@/api/user'
import { useBasicLayout } from '@/hooks/useBasicLayout'
import { copyText } from '@/utils/format'
import type { ResData } from '@/api/types'
import { marked } from 'marked'
const useGlobalStore = useGlobalStoreWithOut()
const props = defineProps<Props>()
interface Props {
  visible: boolean
}

const showModal = ref(false);

const onConfirm = () => {
  console.log('确认操作');
  showModal.value = false;
};

const onCancel = () => {
  console.log('取消操作');
  showModal.value = false;
};

const handleClose = () => {
  useGlobalStore.updateNoticeDialog(false)
}

function handleReminder() {
  useGlobalStore.updateNoticeDialog(false)
  ss.set('showNotice', Date.now() + 24 * 60 * 60 * 1000)
}

watch(
  () => props.visible,
  (newVal) => {
    console.log(newVal)
    showModal.value = newVal;
  },
  { immediate: true }
);


const authStore = useAuthStore()
const darkMode = computed(() => appStore.theme === 'dark')
const logoPath = computed(() => authStore.globalConfig.clientLogoPath)

const appStore = useAppStore()
const emit = defineEmits(['update:visible', 'ok', 'cancel', 'close']);
const { isMobile } = useBasicLayout()
const isLogin = computed(() => authStore.isLogin)
interface Notice {
  noticeInfo: string
  noticeTitle: string
}
const notice = ref<Notice>({
  noticeInfo: '',
  noticeTitle: '',
})

const isEntering = ref(false);
const isLeaving = ref(false);
const loading = ref(true)
const ms = useMessage()

const html = computed(() => {
  if (!notice.value.noticeInfo)
    return ''
  return marked(notice.value.noticeInfo)
})


async function queryNotice() {
  const res: ResData = await fetchGetGlobalNoticeAPI()
  const { success, data } = res
  if (success)
    notice.value = data
  await nextTick()
  attachButtonListeners()
}

async function afterEnter() {
  await queryNotice()
  loading.value = false
}

// async function openDrawerAfter() {
//   await queryNotice()
//   loading.value = false
// }
const inviteCode = computed(() => authStore.userInfo.inviteCode)
const domain = computed(() => {
  let domain = `${window.location.protocol}//${window.location.hostname}`
  if (window.location.port)
    domain += `:${window.location.port}`
  return domain
})

async function genMyInviteCode() {
  const res: ResData = await fetchGenInviteCodeAPI()
  if (!res.data)
    return ms.error(res.message)
  authStore.getUserInfo()
  return res.data
}

function attachButtonListeners() {
  const container = document.querySelector('.markdown-body-generate')
  if (container) {
    container.removeEventListener('click', handleButtonEvent)
    container.addEventListener('click', handleButtonEvent)
  }
}

function handleButtonEvent(e: Event) {
  if (!isLogin.value) {
    ms.warning('请登录后生成链接')
    authStore.setLoginDialog(true)
    return
  }

  const target = e.target as HTMLElement
  console.log(target.tagName)
  if (target.tagName === 'DIV') {
    const action = target.getAttribute('data-action')
    if (action) handleButtonClick(action)
  }
}

async function handleButtonClick(action: string) {
  //根据 action 的值执行相应操作
  if (action === 'accept') {
    if (!inviteCode.value) {
      let inviteCode = await genMyInviteCode()
      const inviteUrl = `${domain.value}/?inVitecode=${inviteCode}`
      copyText({ text: inviteUrl })
      return
    }
    const inviteUrl = `${domain.value}/?inVitecode=${inviteCode.value}`
    copyText({ text: inviteUrl })
    ms.success('复制成功')
  } else if (action === 'decline') {

  }
}

watch(
  () => props.visible,
  (newVal) => {
    if (newVal) {
      isEntering.value = true;
      isLeaving.value = false;
    } else {
      isLeaving.value = true;
      isEntering.value = false;
    }
  },
  { immediate: true }
);

</script>

<style>
.custom-cancel-btn {
  background: #f5f5f5;
  color: #333;
  border: 1px solid #ddd;
  padding: 6px 12px;
  cursor: pointer;
}

.custom-ok-btn {
  background: #28a745;
  color: #fff;
  border: none;
  padding: 6px 12px;
  cursor: pointer;
}

.custom-ok-btn:hover,
.custom-cancel-btn:hover {
  opacity: 0.9;
}
</style>