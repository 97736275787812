<template>
  <div v-if="visible" class="n-modal-mask" @click="onMaskClick">
    <div class="n-modal-container-box w-11/12 md:w-3/5 "
      :class="{ enter: isEntering, leave: isLeaving }" @animationend="onAnimationEnd" @click.stop>
      <!-- <div class="n-modal-header">
        <span>{{ title }}</span>
        :style="{ width: typeof width === 'number' ? `${width}px` : width, height: typeof height === 'number' ? `${height}px` : height }"
        <button class="n-modal-close" @click="handleClose">×</button>
      </div> -->
      <div class="n-modal-body">
        <slot />
      </div>
      <!-- <div class="n-modal-footer">
        <slot name="footer">
          <button class="n-modal-btn n-modal-btn-default" @click="handleCancel">
            {{ cancelText }}
          </button>
          <button class="n-modal-btn n-modal-btn-primary" @click="handleOk">
            {{ okText }}
          </button>
        </slot>
      </div> -->
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, watch, ref } from 'vue';

const props = defineProps({
  visible: {
    type: Boolean,
    required: true,
  },
  title: {
    type: String,
    default: '弹窗标题',
  },
  // width: {
  //   type: [String, Number],
  //   default: 520,
  // },
  // height: {
  //   type: [String, Number],
  //   default: 520,
  // },
  maskClosable: {
    type: Boolean,
    default: true,
  },
  okText: {
    type: String,
    default: '确认',
  },
  cancelText: {
    type: String,
    default: '取消',
  },
});

const emit = defineEmits(['update:visible', 'ok', 'cancel', 'close', 'after-enter']);

const isEntering = ref(false);
const isLeaving = ref(false);

// 动画结束处理
const onAnimationEnd = () => {
  if (isLeaving.value) {
    emit('update:visible', false);
    isLeaving.value = false;
  }
};

watch(
  () => props.visible,
  (newVal) => {
    if (newVal) {
      isEntering.value = true;
      isLeaving.value = false;
      emit('after-enter');
    } else {
      isLeaving.value = true;
      isEntering.value = false;
    }
  },
  { immediate: true }
);

// 点击遮罩层
const onMaskClick = () => {
  if (props.maskClosable) {
    emit('update:visible', false);
  }
};

// 点击确认按钮
const handleOk = () => {
  emit('ok');
  emit('update:visible', false);
};

// 点击取消按钮
const handleCancel = () => {
  emit('cancel');
  emit('update:visible', false);
};

// 点击关闭按钮
const handleClose = () => {
  emit('close');
  emit('update:visible', false);
  isLeaving.value = true;
  isEntering.value = false;
};


</script>

<style scoped>
.n-modal-mask {
  position: fixed;
  inset: 0;
  background: rgba(229, 229, 229, .5);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.n-modal-container-box {
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.4s cubic-bezier(0.25, 0.1, 0.25, 1);
  /* 丝滑的缓动效果 */
}

.n-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid #f0f0f0;
}

.n-modal-close {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #888;
}

.n-modal-body {
  /* padding: 16px;
  font-size: 14px;
  color: #333; */
}

.n-modal-footer {
  padding: 16px;
  text-align: right;
  border-top: 1px solid #f0f0f0;
}

.n-modal-btn {
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.n-modal-btn-default {
  background: #f5f5f5;
  color: #333;
  margin-right: 8px;
}

.n-modal-btn-primary {
  background: #007bff;
  color: #fff;
}

.n-modal-btn:hover {
  opacity: 0.9;
}

/* @keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  60% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: scale(1); 
  }
} */
.n-modal-container-box {
  position: relative;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-fill-mode: both;
  /* 保留动画的最终状态 */
}

.n-modal-container-box.enter {
  animation-name: fadeIn;
}

.n-modal-container-box.leave {
  animation-name: fadeOut;
}


@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0.8);
    /* 初始稍微缩小 */
  }

  100% {
    opacity: 1;
    transform: scale(1);
    /* 缩放到正常大小 */
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: scale(1);
    /* 从正常大小开始 */
  }

  100% {
    opacity: 0;
    transform: scale(0.8);
    /* 缩小并消失 */
  }
}
</style>